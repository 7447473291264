import {
  OperationFileMetadataResult,
  TaskOperations
} from '../../types/task.types'

export const formatSeconds = (seconds?: number): string => {
  if (!seconds) return ''
  const minutes = Math.floor(seconds / 60)
  const remainingSeconds = Math.floor(seconds % 60)
  return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`
}

export const getFileMetadata = (
  operations: TaskOperations[]
): OperationFileMetadataResult | undefined => {
  return operations?.find((i: any) => i?.name === 'FILEMETADATA_A')
    ?.result as OperationFileMetadataResult
}

import { Trans } from '@lingui/macro'
import React, { useCallback, useState } from 'react'
import { Icon } from 'interface'
import { Button } from 'interface/src/components/button'
import { useRouter } from 'next/router'
import { trigger } from '../../../lib/events'
import styles from './delete-task.module.scss'
import { ModalDeleteTask } from './modal-delete-task'
import { useDeleteTask } from '../../../hooks/tasks/use-delete-task'
import { useEvent } from '../../../hooks/analytics'

interface Props {
  taskId?: string
  setModalIsOpen?: React.Dispatch<React.SetStateAction<boolean>>
  closeDropdown?: () => void
}

export const DeleteTask: React.FC<Props> = ({
  taskId,
  setModalIsOpen,
  closeDropdown
}) => {
  const { pathname, push } = useRouter()
  const { deleteTask, loading } = useDeleteTask()
  const { sendEvent } = useEvent()

  const [openModal, setOpenModal] = useState(false)
  const onOpenModal = useCallback(() => {
    setModalIsOpen?.(true)
    setOpenModal(true)
    sendEvent({
      name: 'voice-studio-project-options-clicked',
      category: 'engagement',
      customAttributes: {
        project_ID: taskId,
        activity: 'Delete'
      }
    })
  }, [setModalIsOpen, sendEvent, taskId])
  const onDismissModal = useCallback(() => {
    setModalIsOpen?.(false)
    setOpenModal(false)
  }, [setModalIsOpen])

  const onConfirm = useCallback(async () => {
    if (taskId) {
      await deleteTask({
        trackId: taskId
      })

      if (pathname !== '/projects') {
        push('/projects')
      } else {
        trigger('revalidate:tasks')
      }
    }
    onDismissModal()
    closeDropdown?.()
  }, [taskId, onDismissModal, deleteTask, pathname, push, closeDropdown])

  return (
    <div className={styles.container}>
      <Button
        color="light"
        variant="text"
        classes="!font-normal px-4 py-2 whitespace-nowrap"
        onClickAction={onOpenModal}
      >
        <Icon name="trash" width={16} height={16} />
        <Trans>Delete project</Trans>
      </Button>

      {openModal && (
        <ModalDeleteTask
          loading={loading}
          open={openModal}
          onDismiss={onDismissModal}
          onConfirm={onConfirm}
          title={<Trans>Are you sure you want to delete this project?</Trans>}
          info={
            <Trans>
              This project and any converted voices will be permanently deleted
              from Voice Studio.
            </Trans>
          }
          image="ic-alert"
        />
      )}
    </div>
  )
}

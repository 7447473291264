import { ModelData } from '../../../store/models'
import { Model, ModelOperation } from '../../../types/model.types'
import {
  OperationEncodeResult,
  OperationParams,
  OperationVoiceTranferResult,
  TaskOperations
} from '../../../types/task.types'
import { AddVoiceParams } from './project-page'

const definePitchShift = (
  pitchShift: OperationParams['pitchShift']
): number => {
  return pitchShift === 'auto' ? 0 : pitchShift
}

export const getOriginalVoice = (
  operations: TaskOperations[]
): Model | null => {
  let model: Model | null = null
  const operation = operations?.find((i) => i.name === 'VOICECONVERSION_A')
  const result = operation?.result as OperationEncodeResult

  if (operation && result?.files?.voice_m4a) {
    model = {
      id: 'original',
      model: 'original',
      icon: 'user-voice',
      name: 'Original',
      metadata: [
        {
          id: '',
          key: '',
          value: ''
        }
      ],
      defaultPitchShift: 0,
      operations: [
        {
          id: operation.id,
          status: operation?.status,
          model: 'original',
          pitchShift: 0,
          result: {
            originalM4a: result.files?.voice_m4a,
            originalWav: result.files?.voice_wav
          }
        }
      ]
    }
  }

  return model
}

export const getOperationData = (operation: TaskOperations): ModelOperation => {
  let files: ModelOperation['result']
  const result = operation?.result as OperationVoiceTranferResult

  if (result?.files) {
    files = {
      originalM4a: result?.files?.original_m4a,
      originalWav: result?.files?.original_wav,
      vocalsM4a: result?.files?.vocals_m4a,
      vocalsWav: result?.files?.vocals_wav
    }
  }

  return {
    id: operation.id,
    model: operation.params.model,
    status: operation?.status,
    pitchShift: definePitchShift(operation?.params?.pitchShift),
    result: files
  }
}

export const getProcessedVoices = (
  operations: TaskOperations[],
  models?: ModelData[],
  localOperations?: AddVoiceParams[],
  deletedOperations?: ModelOperation[]
): Model[] => {
  if (!operations || !operations.length) {
    return []
  }

  const list: Model[] = []

  const deletedOperationIds = deletedOperations?.map((i) => i.id) || []

  operations
    .filter((operation) => !deletedOperationIds.includes(operation.id))
    .forEach((operation) => {
      const modelIndex = list.findIndex(
        (model) => model.model === operation.params.model
      )

      if (modelIndex === -1) {
        const model = models?.find(
          (i) => i.identifier === operation.params.model
        )
        list.push({
          id: operation.id,
          model: operation.params.model,
          profile: model?.profile,
          image: model?.metadata.image,
          avatar: model?.metadata.avatar,
          metadata: [
            {
              id: '',
              key: '',
              value: ''
            }
          ],
          name: model?.name || operation.params.model,
          params: operation.params,
          defaultPitchShift: definePitchShift(operation.params.pitchShift),
          operations: [getOperationData(operation)]
        })
      } else {
        list[modelIndex].operations.push(getOperationData(operation))
      }
    })

  localOperations?.forEach((operation) => {
    const modelIndex = list.findIndex(
      (model) => model.model === operation.identifier
    )

    if (modelIndex === -1) {
      const model = models?.find((i) => i.identifier === operation.identifier)
      list.push({
        id: operation.identifier,
        model: operation.identifier,
        profile: model?.profile,
        image: model?.metadata.image,
        avatar: model?.metadata.avatar,
        metadata: [
          {
            id: '',
            key: '',
            value: ''
          }
        ],
        name: model?.name || operation.identifier,
        params: operation,
        defaultPitchShift: operation.pitchShift,
        operations: []
      })
    }
  })

  return list
}

import { DropDown, Icon, useOnClickOutside } from 'interface'
import React, { useRef, useState, RefObject, useCallback } from 'react'
import { Button } from 'interface/src/components/button'
import { EditTask } from '../../modules/task/edit-task'
import { DeleteTask } from '../../modules/task/delete-task'

type DropDownProjectListProps = {
  id: string
  name: string
  visible?: boolean
}

export const DropDownProjectList: React.FC<DropDownProjectListProps> = ({
  id,
  name,
  visible = false
}) => {
  const [isActionDropdownOpen, setIsActionDropdownOpen] = useState(false)
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const ref: RefObject<any> = useRef()

  const closeDropdown = useCallback(() => setIsActionDropdownOpen(false), [])

  useOnClickOutside(ref, () => {
    if (!modalIsOpen) {
      closeDropdown()
    }
  })

  const ignoreDropdownClick = useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => e.stopPropagation(),
    []
  )

  const onOpenDropdown = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      e.preventDefault()
      e.stopPropagation()
      setIsActionDropdownOpen(true)
    },
    [setIsActionDropdownOpen]
  )

  return (
    <>
      <Button
        classes={`${
          visible ? 'visible' : isActionDropdownOpen ? 'visible' : 'invisible'
        } group-hover/item:visible px-3 py-0`}
        size="xs"
        color="light"
        variant="text"
        onClickAction={onOpenDropdown}
      >
        <Icon name="more" width={20} height={20} />
      </Button>
      {isActionDropdownOpen && (
        <span
          role="presentation"
          ref={ref}
          onClick={ignoreDropdownClick}
          className="absolute top-7 right-2 z-50"
        >
          <DropDown>
            <EditTask
              taskId={id}
              name={name}
              setModalIsOpen={setModalIsOpen}
              closeDropdown={closeDropdown}
            />
            <DeleteTask
              taskId={id}
              setModalIsOpen={setModalIsOpen}
              closeDropdown={closeDropdown}
            />
          </DropDown>
        </span>
      )}
    </>
  )
}

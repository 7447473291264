import { t } from '@lingui/macro'
import { useAsyncFn } from 'react-use'
import { config } from '../../../config'
import { Moises } from '../../../lib/graphql'
import { useUserStore } from '../../../store/user'
import { useToast } from '../../../store/toast'

interface UseUpdateTrack {
  error?: boolean
  loading: boolean
  trackUpdated: boolean
  updateTrack(i: { name: string; trackId: string }): void
}

const MoisesCLI = Moises({ apiEndpoint: config.api.endpoint })

export const useUpdateTrack = (): UseUpdateTrack => {
  const { userToken } = useUserStore()
  const { add: addToast } = useToast()

  const [state, updateTrack] = useAsyncFn(
    async ({ name, trackId }: { name: string; trackId: string }) => {
      if (!userToken) return false
      let result
      MoisesCLI.auth(userToken)

      try {
        result = await MoisesCLI.updateTrack({
          trackId,
          name
        })

        addToast({
          icon: null,
          closable: true,
          type: 'success',
          description: t`Renamed successfully`
        })
      } catch (e: any) {
        addToast({
          icon: null,
          type: 'error',
          closable: true,
          description: t`An error occurred when renaming`
        })
      }

      return result
    },
    [userToken, addToast]
  )

  const { loading, error, value } = state

  return {
    loading,
    error: !!error,
    trackUpdated: value === true,
    updateTrack
  }
}

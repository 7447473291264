import { useAsyncFn } from 'react-use'
import { t } from '@lingui/macro'
import { Moises } from '../../../lib/graphql'
import { config } from '../../../config'
import { useUserStore } from '../../../store/user'
import { useToast } from '../../../store/toast'

interface UseDeleteTask {
  taskDeleted: boolean
  error: boolean
  loading: boolean
  deleteTask({ trackId }: { trackId: string }): any
}

const MoisesCLI = Moises({ apiEndpoint: config.api.endpoint })

export const useDeleteTask = (): UseDeleteTask => {
  const { userToken } = useUserStore()
  const { add: addToast } = useToast()

  const [state, deleteTask] = useAsyncFn(
    async ({ trackId }: { trackId: string }) => {
      if (!userToken) return false
      let result
      MoisesCLI.auth(userToken)

      try {
        result = await MoisesCLI.deleteTrack({
          trackId
        })
      } catch (e: any) {
        addToast({
          icon: null,
          type: 'error',
          closable: true,
          description: t`Failed to delete project`
        })
      }

      return result
    },
    [userToken, addToast]
  )

  const { loading, error, value } = state

  return {
    loading,
    error: !!error,
    taskDeleted: value === true,
    deleteTask
  }
}

import React from 'react'
import { ModalConfirmation } from 'interface'
import { Trans } from '@lingui/macro'

interface Props {
  open: boolean
  loading: boolean
  name?: string
  onDismiss(): void
  onConfirm(): void
  image?: string
  info: React.JSX.Element
  title: React.JSX.Element
}

export const ModalDeleteTask: React.FC<Props> = ({
  open,
  loading,
  onDismiss,
  onConfirm,
  image,
  info,
  title
}) => {
  return (
    <ModalConfirmation
      isOpen={open}
      onClose={onDismiss}
      onDismiss={onDismiss}
      onConfirm={onConfirm}
      label="modal-delete-task"
      image={image}
      title={title}
      info={info}
      confirmLabel={<Trans>Delete project</Trans>}
      confirmIcon="trash"
      cancelLabel={<Trans>Cancel</Trans>}
      loading={loading}
      shouldCloseOnOverlayClick
      showBackground
      danger
    />
  )
}
